<template>
  <div v-if="$_.isEmpty(user)" class="box">
    <loading />
  </div>

  <collapse v-else title="Site owner">
    <apart v-if="user.name">
      <p>Name</p>

      <router-link :to="{ path: `/admin/users/${userId}` }">
        <u>{{ user.name }}</u>
      </router-link>
    </apart>

    <apart v-if="user.email" :wrap="false" class="has-overflow-ellipsis-right">
      <p>Email</p>

      <router-link :to="{ path: `/admin/users/${userId}` }">
        <u>{{ user.email }}</u>
      </router-link>
    </apart>

    <apart>
      <p>Joined</p>

      <p>
        <strong>{{
          $moment(user.dateCreated.toDate()).format("Do MMM, YYYY")
        }}</strong>
      </p>
    </apart>

    <apart v-if="user.dateOfLastSignIn">
      <p>Last sign in</p>

      <p>
        <strong>{{
          $moment(user.dateOfLastSignIn.toDate()).format("Do MMM, YYYY HH:mm")
        }}</strong>
      </p>
    </apart>
  </collapse>
</template>

<script>
export default {
  name: "UserDetails",
  props: {
    userId: {
      required: true,
      type: String
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    }
  },
  created() {
    this.$store.dispatch("user/observeUser", { userId: this.userId });
  },
  beforeDestroy() {
    this.$store.dispatch("user/unobserveUser", { userId: this.userId });
  }
};
</script>
